
import { ROOT_ACTIONS } from "@/store/actions";
import { GoogleClaimReview } from "@/types";
import { Component, Vue } from "vue-property-decorator";

import * as GoogleClaimServices from '@/services/GoogleClaimsServices';

@Component
export default class Index extends Vue {
  input = "";
  language = 'nl-BE'

  loading = {
    submit: false,
  };

  languages = [
    {
      value: 'nl-BE',
      text: 'Nederlands'
    },
    {
      value: 'en-US',
      text: 'Engels'
    }
  ]

  claims: GoogleClaimReview[] = [];

  async search() {
    try {
      this.loading.submit = true;
      this.claims = await GoogleClaimServices.FindFactcheckGoogle(this.input, this.language);
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: "error",
      });
    } finally {
      this.loading.submit = false;
    }
  }

  selectClaim(claim: GoogleClaimReview) {
    this.$router.push({
      path: "/factchecks/new",
      query: {
        from: "google",
        claim: JSON.stringify(claim),
      },
    });
  }
}
